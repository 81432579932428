import React from 'react';
import { RefreshCcw, AlertCircle } from 'lucide-react';

// Custom Alert Component
const Alert = ({ variant = 'default', children, className = '' }) => {
  const baseStyles = "rounded-lg p-4";
  const variantStyles = {
    default: "bg-gray-100 text-gray-800",
    destructive: "bg-red-50 text-red-900",
  };

  return (
    <div className={`${baseStyles} ${variantStyles[variant]} ${className}`}>
      <div className="flex items-start gap-3">
        <AlertCircle className="w-5 h-5 text-red-600 flex-shrink-0 mt-0.5" />
        <div className="flex-1">
          {children}
        </div>
      </div>
    </div>
  );
};

const AlertTitle = ({ children }) => (
  <h5 className="font-semibold mb-1">{children}</h5>
);

const AlertDescription = ({ children }) => (
  <div className="text-sm">{children}</div>
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    
    // You can log the error to an error reporting service here
    console.error('Error caught by boundary:', error, errorInfo);
  }

  handleReset = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4">
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>Something went wrong</AlertTitle>
            <AlertDescription>
              {this.state.error?.message || 'An unexpected error occurred'}
            </AlertDescription>
          </Alert>
          
          <button
            onClick={this.handleReset}
            className="flex items-center gap-2 px-4 py-2 text-sm bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
          >
            <RefreshCcw className="w-4 h-4" />
            Try Again
          </button>
          
          {process.env.NODE_ENV === 'development' && (
            <pre className="mt-4 p-4 bg-gray-100 rounded-lg overflow-auto text-sm">
              {this.state.errorInfo?.componentStack}
            </pre>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

const ChatMessageErrorBoundary = ({ children, messageId, onRetry }) => {
  const handleReset = () => {
    if (onRetry) {
      onRetry(messageId);
    }
  };

  return (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  );
};

export { ErrorBoundary, ChatMessageErrorBoundary };